import { Component, OnInit, OnDestroy, ViewChild, Input, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { TaskService } from "../services/task.service";

import { ThemeModel } from '../model/theme.model';
import { TaskModel } from '../model/task.model';
import { JwtService } from '../services/jwt.service';
import { FileUpload } from 'primeng/primeng';
import { environment } from '../../environments/environment';
import { NotifyService } from '../notify/notify.service';
import { DisplayService } from '../services/display.service';
import { QuillService } from '../services/quill.service';

/**
 * p-edit je izbacen - zato sto je pravio beskonacne petlje... u verziji 6.1.4 primeNG, angular 6.1.10
 * Za sada je samo ovde izbacen p-edit, na ostalim  mestima se koristi! (20181012)
 * Vracen p-editor svuda kao extenzija p-editor-ext (vidi tu komponentu) 20181211
 */
@Component({
    selector: 'task-add-v2',
    templateUrl: './task-add-v2.component.html',
    styleUrls: ['./task-add-v2.component.scss'],
    providers: [QuillService]
})
export class TaskAddV2Component implements OnInit, OnDestroy {


    @ViewChild('fileInput') fileInput: FileUpload;

    // Theme for this task 
    @Input()
    theme: ThemeModel;

    private taskAddForm: FormGroup;

    /** panel for ritch text edit in description*/
    private collapsed: boolean = true;

    private quill: any;

    // url used when we attach file to comment - different from simple text comment!s
    private fileUploadURL: string;

    constructor(
        private taskService: TaskService,
        private displayService: DisplayService,
        private notifyService: NotifyService,
        private jwtService: JwtService,
        private quillService: QuillService,
        private fb: FormBuilder
    ) {
        this.fileUploadURL = environment.apiUrl + '/fileUpload/task';
    }

    ngOnInit() {
        this.initTaskAddForm();
    }

    ngOnDestroy() {
    }

    createTask() {
        if (this.fileInput.files && this.fileInput.files.length > 0) {
            // user add file for upload, go with combine upload + task
            // see onBeforeSend(event)  
            this.fileInput.upload();
        } else {
            let model: TaskModel = this.getFormData();
            this.taskService.create(model).subscribe(
                task => {
                    this.displayService.themeChanged(model.themeId);
                },
                error => {
                    console.log(error);
                    this.notifyService.sendToExpLessServer('greska prilikom kreiranja teme');
                    this.notifyService.showError('Greska', 'prilikom kreiranja teme');
                },
                () => {
                    // reset form to empty values (buton will be disabled because input's are manadatory)
                    this.initTaskAddForm();
                });
        }

    }

    private initTaskAddForm() {
        this.taskAddForm = this.fb.group({
            themeId: -1,
            name: ['', Validators.required],
            description: [''],
            userWorkUpload: ['']
        });
        // clear quill editor
        if (this.quill)
            this.quill.setContents([{ insert: '\n' }]);
    }

    /**
     * QUILL
     */
    taskEditorInit(event) {
        this.quill = event.editor;
        const toolbar = this.quill.getModule('toolbar');
        toolbar.addHandler('image', () => {
            this.quillService.selectLocalImage(
                this.quill, 'theme_' + this.theme.groupId + '_' + this.theme.themeId);
        });
    }

    /**
     * fill ThemModel form input form. Set description form QILL
     */
    private getFormData(): TaskModel {
        let model: TaskModel = this.taskAddForm.value;
        //*** 20181010, change to get raw html from quil */
        //
        // set from quill directly (Angular cut somewhere img tags..)
        //
        model.description = this.quill.root.innerHTML;
        // confirm themeId
        model.themeId = this.theme.themeId
        return model;
    }


    /**
     * 
     * FileUpload, action before send -> 
     *  -  add  JWT token! and 
     *  -  fill comment text part: name and desctirpion
     */
    onBeforeSend(event) {
        console.log('onBefore File Send', event);

        let model: TaskModel = this.getFormData();
        model.themeId = this.theme.themeId;

        event.xhr.setRequestHeader("Authorization", `Bearer ${this.jwtService.getAccessToken()}`);
        event.formData.append('dataJson', JSON.stringify(model));
    }

    onUpload(event) {

        console.log('Theme, onUpload', event);

        // reset input form
        this.initTaskAddForm();
        // update cache with new task, while in backgorund refreshing all themes   
        this.taskService.addToLocalCache(JSON.parse(event.xhr.response));
        // UI change
        this.displayService.themeChanged(this.theme.themeId);
    }
}