import * as moment from 'moment';
import { FileModel } from './fileModel.model';

export class TaskModel {

    themeId: number = -1;
    taskId: number = -1;
    name: string;
    description: string;
    orderIndex: number = -1;

    likes: number;
    createdAt: moment.Moment;
    statusAt: moment.Moment;
    userId: number;
    userName: string;
    userInitials: string;
    userAvatarUrl: string;

    fileModel: FileModel;

    userWorkUpload: boolean = false;

    //UI
    dropEffect: boolean = false;
    dropEffectNotAllowed: boolean = false;
    editMode: boolean = false;
}