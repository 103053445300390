import { Component, OnInit, OnDestroy } from '@angular/core';
import { StyleModel } from 'src/app/model/style.model';
import { DisplayService } from 'src/app/services/display.service';
import { Router, NavigationEnd } from '@angular/router';
import { GroupService } from 'src/app/services/group.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

declare var ga: Function;

/**
 * watch for window height - it is calculated in this compponent dinamicaly
 */
@Component({
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit, OnDestroy {

  public pScrollHeightMiddle: StyleModel = new StyleModel();
  public pScrollHeightRight: StyleModel = new StyleModel();

  private smallDevice: boolean = false;
  private subsAppHeight: Subscription = new Subscription();


  constructor(
    public displayService: DisplayService,
    public router: Router,
    private groupService: GroupService
  ) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });

    this.displayService.isSmallDevice.subscribe(data => {
      this.smallDevice = data;
    });

    this.pScrollHeightMiddle.width = '100%';
    this.pScrollHeightMiddle.height = this.calculateUseAppHeght(200) + 'px';

    this.pScrollHeightRight.width = '100%';
    this.pScrollHeightRight.height = this.calculateUseAppHeght(200) + 'px';

  }

  /** calculate actual space that app hase - minus top bar, footer... */
  private calculateUseAppHeght(clientHeight: number): number {
    // 50px -> breadcrumb
    return (clientHeight - 2 * environment.sectionPadding - environment.panelHeaderHeight);
  }


  ngOnInit() {
    this.groupService.getAllGroups();

    this.subsAppHeight = this.displayService.appHeigh$.subscribe(height => {
      this.pScrollHeightMiddle.height = this.calculateUseAppHeght(height) + 'px';
      // right panel dose not have breadcrumd, add this height
      this.pScrollHeightRight.height = this.calculateUseAppHeght(height) + environment.panelHeaderHeight + 'px';
      console.log(this.pScrollHeightMiddle, this.pScrollHeightRight);
    });
  }

  ngOnDestroy() {
    this.subsAppHeight.unsubscribe();
  }

}
