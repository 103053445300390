import { Component, OnDestroy } from '@angular/core';
import { AppComponent } from './app.component';
import { Subscription } from 'rxjs';
import { UserService } from './services/user.service';
import { GroupService } from './services/group.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html'
})
export class AppSideBarComponent implements OnDestroy {

    isAuthenticated: boolean = false;

    private subscription: Subscription = new Subscription();
    private subsUsers: Subscription;

    private userCount: number = 0;

    constructor(public app: AppComponent,
        private userService: UserService,
        private groupService: GroupService, ) { }

    ngOnInit() {
        this.subscription = this.userService.userData$.subscribe(data => {
            this.isAuthenticated = data.isAuthenticated;
        });

        this.subsUsers = this.groupService.groupUsers$.subscribe(data => {
            this.userCount = data.length;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.subsUsers.unsubscribe();
    }
}
