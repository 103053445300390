import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { TaskService } from "../services/task.service";
import { Subscription } from 'rxjs';
import { PlutaRouterService } from '../services/pluta-router.service';
import { NextViewService } from '../services/nextview.service';

/**
 * navigacija zadataka
 */
@Component({
    selector: 'task-nav',
    styleUrls: ['./task-nav.component.scss'],
    templateUrl: './task-nav.component.html',
})
export class TaskNavigationComponent implements OnInit, OnDestroy {

    private subSelectedTask: Subscription = new Subscription();

    private previouoseTaskName = '';
    private nextTaskName = '';

    constructor(
        private plutaRouterService: PlutaRouterService,
        private taskService: TaskService,
        private nextViewService: NextViewService,
    ) {

    }

    ngOnInit() {
        this.subSelectedTask = this.taskService.selectedTask$.subscribe(data => {
            this.previouoseTaskName = this.nextViewService.getPreviouseOnSelectedTaskName();
            this.nextTaskName = this.nextViewService.getNextOnSelectedTaskName();
        });
    }

    ngOnDestroy() {
        this.subSelectedTask.unsubscribe();
    }

    private navigateLeft() {
        this.plutaRouterService.navigatePreviouseTask();
    }

    private navigateRight() {
        this.plutaRouterService.navigateNextTask();
    }

}