import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MenuItem, ConfirmationService } from 'primeng/primeng';
import { Subscription } from 'rxjs';

import { GroupService } from "../services/group.service";
import { DisplayService } from "../services/display.service";

import { GroupModel } from '../model/group.model';
import { UserService } from '../services/user.service';
import { ChatService } from '../services/chat.service';
import { ChatInvokeModel } from '../model/chat-invoke.model';
import { AtomUserModel } from '../model/atom-user.model';
import { DisplayUiModel } from '../model/display.ui.model';
import { StyleModel } from '../model/style.model';
import { NotifyService } from '../notify/notify.service';
import { environment } from 'src/environments/environment';

/**
 * watch for window height - it is calculated in this compponent dinamicaly
 */
@Component({
    selector: 'group-users',
    templateUrl: './group-users.component.html'
})
export class GroupUsersComponent implements OnInit, OnDestroy, AfterViewInit {

    public pScrollHeight: StyleModel = new StyleModel();

    private groupName: string = '';
    private userCount: number = 0;

    group: GroupModel;
    private subsSelectedGroup: Subscription = new Subscription();

    users: Array<AtomUserModel>;
    selectedOptions: Array<MenuItem>;
    selectedUser: AtomUserModel;

    private subsAppHeight: Subscription = new Subscription();

    private subsUsers: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private groupService: GroupService,
        private displayService: DisplayService,
        private userService: UserService,
        private notifyService: NotifyService,
        private confirmationService: ConfirmationService,
        private chatService: ChatService
    ) {

        this.pScrollHeight.width = '100%';
        this.pScrollHeight.height = this.calculateUseAppHeght(200) + 'px';
    }


    /** calculate actual space that app hase - minus top bar, footer... */
    private calculateUseAppHeght(clientHeight: number): number {
        // 1/3 of all height goes to users
        return (clientHeight - 2 * environment.panelHeaderHeight - 2 * environment.sectionPadding) / 3
    }


    ngOnInit() {

        this.subsAppHeight = this.displayService.appHeigh$.subscribe(height => {
            this.pScrollHeight.height = this.calculateUseAppHeght(height) + 'px';
        });

        this.subsSelectedGroup = this.groupService.selectedGroup$.subscribe(data => {
            // console.log('group-users - subs : ', data);
            this.group = data.group;
            if (this.group.groupId >= 0) {
                this.groupName = data.group.name;
            }
        });

        this.subsUsers = this.groupService.groupUsers$.subscribe(data => {
            this.users = data;
            this.userCount = data.length;
        });

    }

    ngOnDestroy() {
        // console.log('-------------- ngOnDestroy ');
        this.cdr.detach();
        this.subsSelectedGroup.unsubscribe();
        this.subsUsers.unsubscribe();
        this.subsAppHeight.unsubscribe();
    }

    ngAfterViewInit() {
        console.log('all done loading ');
        // this.subsSelectedGroup = this.groupService.selectedGroup$.subscribe(data => {
        //     console.log('ngAfterViewInit GU ', data);
        //     this.group = data.group;
        //     if (this.group.groupId >= 0) {
        //         this.groupName = data.group.name;
        //     }
        // });

    }



    /**
      * Authorization
      */
    isGroupAdmin(userId: number): boolean {
        try {
            return this.groupService.isGroupAdmin(userId);
        }
        catch (error) {
            console.log(error); return false;
        }
    }
    isCreator(userId: number): boolean {
        return userId === this.group.userId;
    }

    /** Is user authorise to see controls */
    showControls(): boolean {
        let result: boolean = false;
        result = this.isGroupAdmin(this.userService.getDataStore().user.id);
        return result;
    }

    // must be done this way because otherwise PrimeNG throws ObjectUnsubscribedError 
    openMenu(groupUser: AtomUserModel, menu: any, event: any) {
        // event.stopPropagation();
        this.selectedOptions = [];
        if (groupUser.id !== this.group.userId) {
            let deleteItem: MenuItem = {
                label: 'Ukloni iz grupe',
                command: () => {
                    this.removeUserFormGroup(groupUser);
                }
            };
            this.selectedOptions.push(deleteItem);
        }

        if (!this.groupService.isGroupAdmin(groupUser.id)) {
            let adminItem: MenuItem = {
                label: 'Postavi za admina',
                command: () => {
                    this.setToAdmin(groupUser);
                }
            };
            this.selectedOptions.push(adminItem);

        } else {
            let adminRemoveItem: MenuItem = {
                label: 'Ukloni admina',
                command: () => {
                    this.removeAdmin(groupUser);
                }
            };
            this.selectedOptions.push(adminRemoveItem);
        }


        // PrimeNg's event for showing menu
        menu.show(event);
    }

    startChat(user: AtomUserModel): void {
        let sameUser: boolean = user.id === this.userService.getDataStore().user.id;
        if (!sameUser) {
            let chatInvoke: ChatInvokeModel = new ChatInvokeModel();
            chatInvoke.userId2 = user.id;
            this.chatService.invokeChat(chatInvoke);
        }
    }

    /**
     *  CRUD for user in group: add/remove from group, set/reset as admin
     */
    private removeUserFormGroup(user: AtomUserModel) {
        this.confirmationService.confirm({
            message: `Da li ste sigurni da zelite da uklonite ${user.userName} iz grupe ${this.group.name}?`,
            accept: () => {
                this.groupService.removeUserFromGroup(user.id, this.group).subscribe(data => {
                    this.userCount = data.length;
                });
            },
            reject: () => {
                // do nothing
            }
        });
    }

    private setToAdmin(user: AtomUserModel) {
        this.groupService.setAsAdminOfGroup(user.id, this.group)
            .subscribe(data => {
                this.notifyService.showInfo('OK', 'Korisnik je postavljen za admina');
            });
    }

    private removeAdmin(user: AtomUserModel) {
        this.groupService.removeAdminOfGroup(user.id, this.group)
            .subscribe(data => {
                this.notifyService.showInfo('OK', 'Korisniku su uklonjena Admin prava');
            });
    }



}