import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MenuItem, ConfirmationService } from 'primeng/primeng';

import { ThemesService } from '../services/theme.service';

import { ThemeModel } from '../model/theme.model'; 
import { GroupService } from '../services/group.service';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { NotifyService } from '../notify/notify.service';
import { environment } from '../../environments/environment'; 

/**
 * call from group compponent. Group set current theme as InputAgrument
 */
@Component({
    selector: 'theme-view',
    templateUrl: './theme-view.component.html'
})
export class ThemeViewComponent implements OnInit, OnDestroy {

    // show/hide controll button base on component control - not user autorization
    @Input() showControl: boolean;

    private theme: ThemeModel = new ThemeModel();
    /**
     * use this way of binding - parent can chenge input - child will folow
     */
    @Input('theme')
    set themeOnInput(theme: ThemeModel) {
        // console.log('----------------------**********themeOnInput', theme);
        this.theme = theme;
        // get edit state - if user open this theme for edit and refresh is comming - to preserve open editing window
        this.editTheme = theme.editMode;
    }
    selectedOptions: Array<MenuItem>;

    showAddComment: boolean = false;
    showAddTask: boolean = false;

    editTheme: boolean = false;
    tabIndex: number = 2;

    collapsed: boolean = true;

    // theme accordion 
    // private subThemeAction: Subscription = new Subscription();

    constructor(
        private themesService: ThemesService,
        private groupService: GroupService,
        private router: Router,
        private confirmationService: ConfirmationService, 
        private userService: UserService, 
        private notifyService: NotifyService) {
    }

    ngOnInit() {
        // console.log('themeView onInit');
        this.selectedOptions = [];
        let deleteItem: MenuItem = {
            label: 'Obrisi',
            command: () => {
                this.delete();
            }
        };
        this.selectedOptions.push(deleteItem);
        let editItem: MenuItem = {
            label: 'Izmeni',
            command: () => {
                this.editTheme = true;
            }
        };
        this.selectedOptions.push(editItem);
    }

    ngOnDestroy(): void {
        // this.subThemeAction.unsubscribe();
    }

    private openMenu(menu, event) {
        console.log('open menu', event);
        // must stop propagation or DOM will be all rendered and we';; have large timeouts!
        // event.stopPropagation();
        // PrimeNg's event for showing menu
        menu.show(event);
    }

 
    finishEdit(event) {
        console.log('finis editing theme-view');
        this.editTheme = false;
    }

    /**
     * Authorisation
     */
    isGroupAdmin(): boolean {
        try {
            return this.groupService.isGroupAdmin(this.userService.getDataStore().user.id);
        }
        catch (error) {
            console.log(error); return false;
        }
    }
    isCreator(): boolean {
        return this.theme.userId === this.userService.getDataStore().user.id;
    }
    /** Is user authorise to see controls */
    showControlPerUser(): boolean {
        let result: boolean = false;
        result = this.isGroupAdmin() || this.isCreator();
        return result;
    }

    toggleComments(event: any) {
        this.showAddComment = !this.showAddComment;
        this.showAddTask = false;
    }

    toggleTask(event: any) {
        this.showAddTask = !this.showAddTask;;
        this.showAddComment = false;
    }
 
    public delete() {
        this.confirmationService.confirm({
            message: `Da li ste sigurni da zelite da obrisete temu: "${this.theme.name}" ?`,
            accept: () => {
                this.themesService.delete(this.theme)
                    .subscribe(
                        data => {
                        },
                        error => {
                            console.log(error);
                        },
                        () => {
                            let group = this.groupService.getSelectedGroup();
                            this.groupService.showGroup(group);
                            // display group panel   
                            if (group.groupId >= 0)
                                this.router.navigate(['group/' + group.groupId]);
                            else {
                                console.log('navigating groups root');
                                this.router.navigate(['group/all']);
                            }
                        });
            },
            reject: () => {
                // do nothing
            }
        });
    }

    downloadFilePath(uuidFileName: string) {
        return `${environment.apiUrl}/fileDownload/theme/${this.theme.themeId}/${uuidFileName}`;
    }

    downloadFilePathOld(uuidFileName: string, originalFileName: string) {
        console.log('theme downloadFilePath theme', uuidFileName, originalFileName);
        this.themesService.downloadFile(this.theme.themeId, uuidFileName)
            .subscribe(data => {
                console.log('blob data: ', data);
                if (data.status === 204) {
                    console.log('file dose not exist');
                    this.notifyService.showError('Dogodila se greška', 'Izabrani file ne postoji na serveru?!');
                    return;
                }
                var link = document.createElement('a');
                var binaryData = [];
                binaryData.push(data.body);
                link.href = window.URL.createObjectURL(new Blob(binaryData, { type: "application/zip" }));
                link.download = originalFileName;
                // link.click();
                link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
            }, err => {
                console.log(err);
            });
    }

}