import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommentModel } from '../model/comment.model';

@Component({
  selector: 'comment-with-replies',
  templateUrl: './comment-with-replies.component.html',
  styleUrls: ['./comment-with-replies.component.css']
})
export class CommentWithRepliesComponent implements OnInit {

  @Input() comment: CommentModel;

  constructor() { }

  ngOnInit() {

  }


}