import { Component, Input, OnInit, ViewChild, OnDestroy, ElementRef, Renderer2 } from '@angular/core';

import { environment } from '../../environments/environment';
import { JwtService } from '../services/jwt.service';
import { WorkService } from '../services/work.service';
import { WorkModel } from '../model/work.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'work-content',
  templateUrl: './work-content.component.html',
  styleUrls: ['./work-content.component.css']
})
export class WorkContentComponent implements OnInit, OnDestroy {

  // url used when we attach work file 
  private fileUploadURL: string;

  // 1:Theme; 2:Task
  @Input() artifactType: number;
  @Input() artifactId: number;

  workContent: WorkModel = new WorkModel();

  uploadInProgress: boolean = false;
  subWorks: Subscription = new Subscription();

  constructor(
    private workService: WorkService,
    private jwtService: JwtService,
    public renderer: Renderer2
  ) {
  }

  ngOnInit() {

    this.fileUploadURL = environment.apiUrl + '/fileUpload/work';

    this.subWorks = this.workService.works$.subscribe(data => {
      console.log('subWorks  ', data);
      this.initWork(data);
      console.log('subWorks WORK ', this.workContent);
    });

  }

  private initWork(data: WorkModel[]): void {

    let works = data.filter(wrk => {
      return +wrk.artifactType === +this.artifactType &&
        +wrk.artifactId === +this.artifactId
    });

    if (works && works.length > 0) {
      console.log('**--', works);
      console.log('**--  0  ', works[0]);
      this.workContent = works[0];
    }
    else {
      this.workContent = new WorkModel();
      this.workContent.artifactId = this.artifactId;
      this.workContent.artifactType = this.artifactType;
    }
  }

  ngOnDestroy(): void {
  }


  /**
   *  Download file for comment
   */

  downloadFilePath(uuidFileName: string) {
    return `${environment.apiUrl}/fileDownload/work/${this.workContent.workId}/${uuidFileName}`;
  }


  /**
   * 
   * FileUpload, action before send -> 
   *  -  add  JWT token! and 
   *  -  fill comment text part: name and desctirpion
   */
  onBeforeSend(event) {
    console.log(event);
    event.xhr.setRequestHeader("Authorization", `Bearer ${this.jwtService.getAccessToken()}`);
    event.formData.append('dataJson', JSON.stringify(this.workContent));
  }

  onUpload(event) {
    console.log(event);
    // update cache with new workContent, while in backgoround refreshing all data   
    this.workService.addToLocalCache(JSON.parse(event.xhr.response));
    this.uploadInProgress = false;
  }

}