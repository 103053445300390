import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { DisplayUiModel } from '../model/display.ui.model';

/**
 * Responisble only for visualisation! what panel/component should be visible/hidden 
 * Not responisble for the data!!
 */
@Injectable()
export class DisplayService {

    private smallDevicesSource = new BehaviorSubject<boolean>(false);
    isSmallDevice = this.smallDevicesSource.asObservable();

    // defaut for us is 200px
    private appHeighSource = new BehaviorSubject<number>(200);
    appHeigh$ = this.appHeighSource.asObservable();

    //
    //

    /** REMOVED 20190102 not used - we moved to side bar */
    // list of groups - left top side panel. Show/Hide
    // private groupListComponentSource = new BehaviorSubject<DisplayUiModel>(new DisplayUiModel());
    // groupListComponent$ = this.groupListComponentSource.asObservable();

    // accordion of themes for one group - right side of panel. Show/Hide
    // private groupThemesPreviewSource = new BehaviorSubject<DisplayUiModel>(new DisplayUiModel());
    // groupThemesPreview$ = this.groupThemesPreviewSource.asObservable();


    // users for one group - left bottom side panel. Show/Hide
    private groupUsersComponentSource = new BehaviorSubject<DisplayUiModel>(new DisplayUiModel());
    groupUsersComponent$ = this.groupUsersComponentSource.asObservable();

    /** selected group has change or CRUD notify all others */
    private groupChangedSource = new BehaviorSubject<DisplayUiModel>(new DisplayUiModel());
    groupChanged$ = this.groupChangedSource.asObservable();

    /** Theme Action: on CRUD notify all others or new Task is selected */
    private themeChangedSource = new BehaviorSubject<DisplayUiModel>(new DisplayUiModel());
    themeChanged$ = this.themeChangedSource.asObservable();

    /** Task Action: on CRUD notify all others or new Task is selected */
    private taskChangedSource = new BehaviorSubject<DisplayUiModel>(new DisplayUiModel());
    taskChanged$ = this.taskChangedSource.asObservable();

    //
    // breadcrumb Action 
    private breadcrumbSource = new BehaviorSubject<number>(0);
    breadcrumb$ = this.breadcrumbSource.asObservable();


    constructor() {
        // initial app height. appComponent is responsible for setting this value
        // top manu bar is 60px
        let appHeight = document.documentElement.clientHeight - 60;
        this.appHeighSource.next(appHeight);
        console.log('appHeight   :  ', appHeight);
    }

    setSmallDevice(value: boolean) {
        this.smallDevicesSource.next(value);
    }
    setWindowHeight(height: number) {
        // 60px top nav bar
        this.appHeighSource.next(height - 60);
    }

    /**
     * ----------------------------------------------------------------
     *                    Group UI Actions 
     * ----------------------------------------------------------------
    */
    showGroupUI(state: boolean) {
        console.log('**--> showGroupUI : ', state);
        let dm = new DisplayUiModel();
        if (state)
            dm.openState = dm.OPEN;
        else
            dm.openState = dm.CLOSE;
        console.log('state   ...  ', state, dm.openState);
        this.groupUsersComponentSource.next(dm);
        // this.groupThemesPreviewSource.next(dm);
        // this.groupListComponentSource.next(dm);
    }

    groupUsersChanged() {
        console.log('**--> groupUsersChanged');
        let dm = new DisplayUiModel();
        dm.openState = dm.REFRESH;
        this.groupUsersComponentSource.next(dm);
    }
    groupChanged(groupId?: number) {
        console.log('**--> groupChanged, on :', groupId);
        let dm = new DisplayUiModel();
        dm.openState = dm.OPEN;
        dm.artifactType = new DisplayUiModel().TYPE_GROUP;
        dm.artifactId = groupId;
        this.groupChangedSource.next(dm);
    }

    /**
     * ----------------------------------------------------------------
     *                    Theme UI Actions 
     * ----------------------------------------------------------------
    */
    themeChanged(themeId: number) {
        console.log('**--> themeChanged :', themeId);
        let dm = new DisplayUiModel();
        dm.openState = dm.OPEN;
        dm.artifactType = new DisplayUiModel().TYPE_THEME;
        dm.artifactId = themeId;
        this.themeChangedSource.next(dm);
        console.log('theme changed - ', themeId);
    }

    /**
     * ----------------------------------------------------------------
     *                    Theme UI Actions 
     * ----------------------------------------------------------------
    */
    taskChanged(taskId: number) {
        console.log('**--> taskChanged :', taskId);
        let dm = new DisplayUiModel();
        dm.openState = dm.OPEN;
        dm.artifactType = new DisplayUiModel().TYPE_TASK;
        dm.artifactId = taskId;
        this.taskChangedSource.next(dm);
    }


    /**
     * ----------------------------------------------------------------
     *                        Breadcrumb 
     * ----------------------------------------------------------------
    */
    breadCrumb(arg: number) {
        console.log('**--> breadCrumb :', arg);
        this.breadcrumbSource.next(arg);
    }
}