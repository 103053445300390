import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from "rxjs";

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TaskModel } from '../model/task.model';

import { map, tap } from 'rxjs/operators';
import { NotifyService } from '../notify/notify.service';
import { ThemesService } from './theme.service';

/**
 * 
 * ********* Use CACHING mechanizam *************
 * 
 * !!! All Tasks for ONE Group and ALL Themes!!!
 * when subscribe, component filter task that are only for it
 */
@Injectable()
export class TaskService {

    private rootPath: string = `${environment.apiUrl}/task`;

    /**All task for ONE group and All Themes! */
    private tasksSource = new BehaviorSubject<Array<TaskModel>>(new Array<TaskModel>());
    tasks$ = this.tasksSource.asObservable();

    // selected Task
    private selectedTaskSource = new BehaviorSubject<TaskModel>(new TaskModel());
    selectedTask$ = this.selectedTaskSource.asObservable();


    constructor(
        private httpClient: HttpClient,
        private notifyService: NotifyService) {

    }


    // Tasks subscription, used for canceling previuose requests that are not finished yet, 
    // but user fast clicked to resend them
    // same object for all thames calls
    private subThems: Subscription;

    getAllTasksForGroup(groupId: number): void {
        console.log('readAllTasksForGroup  ', groupId);
        // clear previous state (task data)
        this.tasksSource.next([]);
        let url: string = `${this.rootPath}/group/${groupId}`;

        if (this.subThems) {
            console.log('unSubscribing...');
            this.subThems.unsubscribe();
        }

        this.subThems = this.httpClient.get<TaskModel[]>(url)
            .subscribe(tasks => {
                this.tasksSource.next(tasks);
            },
                err => {
                    console.log(err);
                });
    }


    getAllTasksForGroupAndSelectCurrent(groupId: number, taskId: number): void {
        console.log('getAllTasksForGroupAndSelectCurrent  ', groupId, taskId);
        // clear previous state (task data)
        this.tasksSource.next([]);
        let url: string = `${this.rootPath}/group/${groupId}`;

        if (this.subThems) {
            console.log('unSubscribing...');
            this.subThems.unsubscribe();
        }

        this.subThems = this.httpClient.get<TaskModel[]>(url)
            .subscribe(tasks => {
                this.tasksSource.next(tasks);
                // select current task
                let filtered = tasks.filter(t => {
                    // must have + sign!! TODO chech why and correct to remove
                    return +t.taskId === +taskId
                });

                if (filtered && filtered.length > 0) {
                    this.selectedTaskSource.next(filtered[0]);
                }
                else {
                    this.selectedTaskSource.next(new TaskModel());
                }
            },
                err => {
                    console.log(err);
                });
    }

    getTasks() {
        return this.tasksSource.getValue();
    }

    getTask(taskId: number) {
        let res = this.tasksSource.getValue().filter(k => k.taskId === taskId)
        if (res && res.length > 0)
            return res[0];
        else {
            this.notifyService.sendToExpLessServer('task is not in the list, when searching by taskId: ' + taskId);
            return new TaskModel();
        }
    }

    getSelectedTask(): TaskModel {
        return this.selectedTaskSource.getValue();
    }

    getById(taskId: number): Observable<TaskModel> {
        let url: string = `${this.rootPath}/${taskId}`;
        console.log('getById', url);
        return this.httpClient.get(url)
            .pipe(
                map(data => <TaskModel>data)
            );
    }

    getByIdAndSetSelected(taskId: number): void {
        console.log('getByIdAndSetSelected', taskId);
        this.getById(taskId)
            .subscribe(data => this.setSelectedTask(data));
    }

    clearTasks(): void {
        console.log('clear all Tasks');
        this.tasksSource.next([]);
    }

    setSelectedTask(task: TaskModel): void {
        if (task)
            this.selectedTaskSource.next(task);
        else
            this.selectedTaskSource.next(new TaskModel());
    }

    setSelectedTaskByIdFromCache(id: number): void {
        console.log('setSelectedTaskByIdFromCache  ', id);
        let res = this.tasksSource.getValue().filter(thm => +thm.taskId === +id);
        if (res && res.length > 0)
            this.setSelectedTask(res[0]);
        else
            this.setSelectedTask(null);

    }

    clearSelectedTask(): void {
        this.selectedTaskSource.next(new TaskModel());
    }

    clearAll(): void {
        // clear all themes
        this.tasksSource.next([]);
        // clear selected theme 
        this.selectedTaskSource.next(new TaskModel());
    }

    switchTasks(groupId: number, themeId: number, task1Id: number, task2Id: number): void {

        let url: string = `${this.rootPath}/${task1Id}/${task2Id}`;
        console.log(url);

        this.httpClient.put(url, '')
            .subscribe(response => {
                this.getAllTasksForGroup(groupId);
            });
    }

    /**
     * 
     * CRUD for task
     * 
     */
    create(task: TaskModel): Observable<TaskModel> {
        let url: string = `${this.rootPath}`;
        return this.httpClient.post<TaskModel>(url, task)
            .pipe(
                tap(task => {
                    this.addToLocalCache(task);
                })
            );
    }

    update(model: TaskModel, groupId: number): Observable<TaskModel> {
        let url: string = `${this.rootPath}`;
        return this.httpClient.put<TaskModel>(url, model)
            .pipe(
                tap(task => {
                    this.updateLocalCache(task);
                })
            );
    }

    delete(model: TaskModel, groupId: number): Observable<TaskModel> {
        let url: string = `${this.rootPath}/${model.taskId}`;
        return this.httpClient.delete<TaskModel>(url)
            .pipe(
                tap(task => {
                    this.removeFromLocalCache(model);
                })
            );
    }


    /* ------------------------------------------------------------------------------------
    * 
    * Cache data operation
    * when we do CRUD, immedeatly after receiving OK from API we localy persist new state 
    * until receved fresh (same) data from api - so user have seemles CRUD operation in case of slow network  
    * 
    * ------------------------------------------------------------------------------------
    */
    updateLocalCache(task: TaskModel) {
        console.log('taks updateLocalCache', task);
        //Find index of specific object using findIndex method.    
        let objIndex = this.tasksSource.getValue().findIndex((obj => +obj.taskId === +task.taskId));
        console.log('task index', objIndex);
        //Update object 
        this.tasksSource.getValue()[objIndex] = task;
    }
    removeFromLocalCache(task: TaskModel) {
        console.log('taks removeFromLocalCache', task);
        //Find index of specific object using findIndex method.    
        let objIndex = this.tasksSource.getValue().findIndex((obj => +obj.taskId === +task.taskId));
        //Update object 
        this.tasksSource.getValue().splice(objIndex, 1);
        // reindex tasks for this theme -> now we have a hole in orderIndex array
        let counter = 10;
        this.tasksSource.getValue()
            .filter(currTask => {
                return +currTask.themeId === +task.themeId;
            }).forEach(element => {
                element.orderIndex = counter;
                counter = counter + 10;
            });
    }
    addToLocalCache(task: TaskModel) {
        console.log('taks addToLocalCache', task);
        //Update object 
        this.tasksSource.getValue().push(task);
    }

    deleteFile(task: TaskModel, uuidFileName: string): Observable<TaskModel> {
        console.log('deleteFile in theme ', task, uuidFileName);
        let url: string = `${this.rootPath}/deletefile/${task.taskId}/${uuidFileName}`;
        return this.httpClient.delete<TaskModel>(url)
            .pipe(
                tap(task => {
                    this.updateLocalCache(task);
                })
            );;
    }

    downloadFile(taskId: number, uuidFileName: string): Observable<any> {

        let url = `${environment.apiUrl}/fileDownload/task/${taskId}/${uuidFileName}`;
        return this.httpClient.get(
            url,
            {
                responseType: 'blob', reportProgress: true, observe: 'events'
            });
    }

}