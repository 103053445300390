import * as moment from 'moment';

export class WorkModel {
    public workId: number = -1;
    public artifactType: number;
    public artifactId: number;
    public status: number;
    public statusAt: moment.Moment;
    public createdAt: moment.Moment;
    public likes: number;
    public workName: string;
    public userName: string;
    public userAvatarUrl: string;
    public userId: number;

    public fileModel: string;



}