import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from 'rxjs';

import { GroupService } from "../services/group.service";
import { ThemesService } from '../services/theme.service';

import { GroupModel } from '../model/group.model';
import { ThemeModel } from '../model/theme.model';
import { FileUpload } from 'primeng/primeng';
import { JwtService } from '../services/jwt.service';
import { environment } from '../../environments/environment';
import { DisplayService } from '../services/display.service';
import { NotifyService } from '../notify/notify.service';
import { QuillService } from '../services/quill.service';

@Component({
    selector: 'theme-add',
    templateUrl: './theme-add.component.html',
    providers: [QuillService]
})
export class ThemeAddComponent implements OnInit, OnDestroy {

    @ViewChild('fileInput') fileInput: FileUpload;
    // url used when we attach file to comment - different from simple text comment!s
    private fileUploadURL: string;

    createThemeForm: FormGroup;

    /** panel for ritch text edit in description*/
    private collapsed: boolean = true;

    group: GroupModel;
    private subSelectedGroup: Subscription = new Subscription();

    createInProgress: boolean = false;
    private quill: any;

    constructor(
        private themesService: ThemesService,
        private notifyService: NotifyService,
        private displayService: DisplayService,
        private quillService: QuillService,
        private groupService: GroupService,
        private jwtService: JwtService,
        private fb: FormBuilder
    ) {
        this.fileUploadURL = environment.apiUrl + '/fileUpload/theme';
    }

    ngOnInit() {
        console.log('ThemeAddComponent onInit');
        this.subSelectedGroup = this.groupService.selectedGroup$.subscribe(data => {
            this.group = data.group;
            this.initForm();
        });
    }

    ngOnDestroy() {
        this.subSelectedGroup.unsubscribe();
    }

    createTheme() {
        this.createInProgress = true;

        if (this.fileInput.files && this.fileInput.files.length > 0) {
            // user add file for upload, go with combine upload + commnet
            // see onBeforeSend(event)  
            this.fileInput.upload();
        }
        else {
            // no file - just commnet to add
            let model: ThemeModel = this.getFormData();
            this.themesService.create(model)
                .subscribe(
                    data => {
                        this.displayService.groupChanged();
                    },
                    error => {
                        console.log(error);
                        this.notifyService.showError('Greska', 'Prilikom kreiranja teme.');
                        this.notifyService.sendToExpLessServer('Greska prilikom kreiranja teme.');
                    },
                    () => {
                        this.createInProgress = false;
                        // this.changeForm.reset(); // removed 20180729 - groupId was lost after first create
                        this.initForm();// add 20180729 - change to init insted of reset!
                    });
        }
    }

    /**
     * QUILL
     */
    editorInit(event) {
        this.quill = event.editor;
        const toolbar = this.quill.getModule('toolbar');
        toolbar.addHandler('image', () => {
            this.quillService.selectLocalImage(this.quill, 'group_' + this.group.groupId);
        });
    }

    /**
     * fill ThemModel form input form. Set description form QILL
     */
    private getFormData(): ThemeModel {
        let model: ThemeModel = this.createThemeForm.value;
        //*** 20181010, change to get raw html from quil */
        //
        // set from quill directly (Angular cut somewhere img tags..)
        //
        model.description = this.quill.root.innerHTML;
        // confirm groupId
        model.groupId = this.group.groupId;
        return model;
    }

    /**
     * 
     * FileUpload, action before send -> 
     *  -  add  JWT token! and 
     *  -  fill comment text part: name and desctirpion
     */
    onBeforeSend(event) {

        console.log('onBefore File Send', event);

        let model: ThemeModel = this.createThemeForm.value
        model.groupId = this.group.groupId;

        event.xhr.setRequestHeader("Authorization", `Bearer ${this.jwtService.getAccessToken()}`);
        event.formData.append('dataJson', JSON.stringify(model));
    }

    onUpload(event) {
        console.log('Theme, onUpload', event);
        // reset view
        this.createInProgress = false;
        this.initForm();// add 20180729 - change to init insted of reset!
        // update cache with new theme   
        this.themesService.addToLocalCache(JSON.parse(event.xhr.response));
        // UI change
        this.displayService.groupChanged();
    }

    onRemove(event) {
        console.log('Theme, onUpload', event);

    }

    private initForm() {

        this.createThemeForm = this.fb.group({
            groupId: [this.group.groupId],
            name: ['', Validators.required],
            userWorkUpload: [false],
            description: ['']
        });
    }

}