import { Injectable } from '@angular/core';
import { TaskModel } from '../model/task.model';
import { ThemeModel } from '../model/theme.model';
import { TaskService } from './task.service';
import { ThemesService } from './theme.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NextViewService {

  // Theme neighbours
  private previouseTheme: ThemeModel;
  private nextTheme: ThemeModel;


  // Task neighbours
  private previouseTask: TaskModel;
  private nextTask: TaskModel;

  //observer
  private nextViewSource = new BehaviorSubject<boolean>(true);
  nextView$ = this.nextViewSource.asObservable();

  constructor(
    private themesService: ThemesService,
    private taskService: TaskService, ) {

    taskService.selectedTask$.subscribe(data => {
      this.processTaskNeiborghs(data);
    })

    themesService.selectedTheme$.subscribe(data => {
      this.processThemeNeiborghs(data);
    })

  }


  //
  //-------------------------------------------
  //
  // Previouse / Next THEME - used in navigation
  //
  //-------------------------------------------
  //
  private processThemeNeiborghs(theme: ThemeModel) {

    // previouse
    let previouse = this.themesService.getThemes().filter(t => {
      return t.groupId === theme.groupId
    }).filter(t => {
      return t.orderIndex === theme.orderIndex - 10;
    });

    if (previouse)
      this.previouseTheme = previouse[0];
    else
      this.previouseTheme = null;

    // next
    let next = this.themesService.getThemes().filter(t => {
      return t.groupId === theme.groupId
    }).filter(t => {
      return t.orderIndex === theme.orderIndex + 10;
    });

    if (next)
      this.nextTheme = next[0];
    else
      this.nextTheme = null;
  }

  /** previouse and next Theme objects */
  public getPreviouseOnSelectedTheme() {
    return this.previouseTheme
  }
  public getNextOnSelectedTheme() {
    return this.nextTheme
  }

  /** previouse and next Theme names */

  public getPreviouseOnSelectedThemeName(): string {
    if (this.previouseTheme)
      return this.previouseTheme.name;
    else
      return 'Pocetak';
  }

  public getNextOnSelectedThemeName(): string {
    if (this.nextTheme)
      return this.nextTheme.name;
    else
      return 'Kraj';
  }


  //
  // Previouse / Next TASK - used in navigation
  //
  private processTaskNeiborghs(task: TaskModel) {
    console.log('processTaskNeiborghs', task);
    // previouse
    let previouse = this.taskService.getTasks().filter(t => {
      return t.themeId === task.themeId
    }).filter(t => {
      return t.orderIndex === task.orderIndex - 10;
    });
    console.log('ppkk previouse', previouse);

    if (previouse)
      this.previouseTask = previouse[0];
    else
      this.previouseTask = null;

    // next
    let next = this.taskService.getTasks().filter(t => {
      return t.themeId === task.themeId
    }).filter(t => {
      return t.orderIndex === task.orderIndex + 10;
    });

    console.log('ppkk next', next);

    if (next)
      this.nextTask = next[0];
    else
      this.nextTask = null;
 
    this.nextViewSource.next(true);

  }

  /** previouse and next task objects */
  public getPreviouseOnSelectedTask() {
    return this.previouseTask
  }
  public getNextOnSelectedTask() {
    return this.nextTask
  }

  /** previouse and next task names */

  public getPreviouseOnSelectedTaskName(): string {
    if (this.previouseTask)
      return this.previouseTask.name;
    else
      return this.getPreviouseOnSelectedThemeName();
  }

  public getNextOnSelectedTaskName(): string {
    if (this.nextTask)
      return this.nextTask.name;
    else
      return this.getNextOnSelectedThemeName();
  }
}
