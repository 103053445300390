import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { GroupService } from "../services/group.service";
import { ThemesService } from '../services/theme.service';

import { GroupModel } from '../model/group.model';
import { ThemeModel } from '../model/theme.model';
import { DisplayService } from '../services/display.service';
import { DisplayUiModel } from '../model/display.ui.model';
import { Router, ActivatedRoute } from '@angular/router';
import { PlutaRouterService } from '../services/pluta-router.service';

@Component({
    selector: 'themes-preview',
    templateUrl: './themes-preview.component.html',
    styleUrls: ['./themes-preview.component.scss']
})
export class ThemesPreviewComponent implements OnInit, OnDestroy, AfterViewInit {

    // group 
    private group: GroupModel;
    private subSelectedGroup: Subscription = new Subscription();

    // group action
    private subGroupChanged: Subscription = new Subscription();

    // themes
    private themes: Array<ThemeModel> = new Array();
    private subThemes: Subscription = new Subscription();

    // drag and drop
    private draggedTheme: ThemeModel;
    private dragStarted: boolean = false;

    private subsDisplayThemes: Subscription = new Subscription();

    // private hideUI: boolean = true;
    private subSelectedTheme: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private groupService: GroupService,
        private themesService: ThemesService,
        private displayService: DisplayService,
        private plutaRoute: PlutaRouterService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {

        this.subSelectedGroup = this.groupService.selectedGroup$.subscribe(data => {
            this.group = data.group;
        });

        // this.subsDisplayThemes = this.displayService.groupThemesPreview$
        //     .subscribe((model: DisplayUiModel) => {
        //         this.hideUI = (+model.openState === +model.CLOSE);
        //     });

        this.subGroupChanged = this.displayService.groupChanged$.subscribe(data => {
            // console.log('themesPreview: group action subscription');
            this.themes = this.themesService.getThemes();
        });

        this.subSelectedTheme = this.themesService.selectedTheme$.subscribe(data => {
            console.log('ThemePreview: incoming new theme from subscription; ', data);
        })

    }

    ngAfterViewInit() {
        this.subThemes = this.themesService.themes$.subscribe((themes: Array<ThemeModel>) => {
            console.log('accordion init, themes arrive');
            this.themes = themes;
            this.cdr.detectChanges();
        });

    }

    ngOnDestroy() {
        this.subSelectedGroup.unsubscribe();
        this.subThemes.unsubscribe();
        this.subGroupChanged.unsubscribe();
        this.subSelectedTheme.unsubscribe();
        this.subsDisplayThemes.unsubscribe();
    }

    isActive(theme: ThemeModel): boolean {
        if (theme && this.themesService.getSelectedTheme()) {
            if (theme.themeId === this.themesService.getSelectedTheme().themeId)
                return true
            else
                return false;
        }
        else
            return false;
    }

    /**
    * Panel emulation of accordion
    */
    onPanelOpen(event, theme: ThemeModel) {
        console.log('ON PANEL OPEN------------------------', theme);
        /** check if this theme is already selected -> do nothing in this case 
         * @20181125 remove this option 
        */
        // if (this.themesService.getSelectedTheme().themeId === theme.themeId) {
        //     // theme is alredy active, do nothing
        //     console.log('theme is alredy active');
        //     return;
        // }
        // set data
        this.themesService.setSelectedTheme(theme);
        // show UI
        this.displayService.themeChanged(theme.themeId);

        if (this.plutaRoute.isUrlPublic()) {
            // public group url 
            console.log('public url  ', this.router.url);
            this.router.navigate(
                [this.group.publicUrl, theme.themeId],
                { relativeTo: this.route }
            );
        }
        else {
            // user is logged in, regular URL path, navigate to Theme page
            console.log('regular url  ', this.router.url);
            this.router.navigate(
                [this.group.groupId, theme.themeId],
                { relativeTo: this.route }
            );
        }

    }


    /**
     * 
     * Drag&Drop
     * 
     * 
     */

    /**
     * 
     * @param dropped check if dragable theme is Uper then droppable theme for ONE step.
     * In this case we do not show UI for dropping area - since nothing will happend - we only move one step up bu definition
     */
    private checkOrder(dropped: ThemeModel) {
        let dragIndex = this.themes.indexOf(this.draggedTheme);
        let dropIndex = this.themes.indexOf(dropped);
        console.log(dragIndex, dropIndex);
        if (dragIndex - dropIndex === -1) {
            // dragged is one step upper then dropped. Do nothing
            console.log('Dragged is one step in forn of dropped object. Nothing is changed...');
            dropped.dropEffectNotAllowed = false;
            return true;
        }
        return false;
    }

    dragStart(event, theme: ThemeModel) {
        console.log('drag start')
        this.dragStarted = true;
        this.draggedTheme = theme;
    }

    drop(event, theme: ThemeModel) {
        console.log('switching...   ', this.draggedTheme.themeId, theme.themeId);
        if (this.draggedTheme) {
            if (this.draggedTheme.themeId !== theme.themeId) {

                // the are different, check now if they are next to each othe
                if (this.checkOrder(theme))
                    return;

                this.themesService.switchThemes(this.group.groupId, this.draggedTheme.themeId, theme.themeId);

            }
            else {
                console.log('same Theme - nothing to switch');
            }
        }
        this.draggedTheme.dropEffect = false;
        this.draggedTheme.dropEffectNotAllowed = false;
        theme.dropEffect = false;
        theme.dropEffectNotAllowed = false;
    }

    dragEnd(event) {
        console.log('drag stop')
        this.draggedTheme = null;
        this.dragStarted = false;
    }

    dragEnter(event, theme: ThemeModel) {
        console.log('dragEnter');
        if (this.draggedTheme && this.draggedTheme) {
            if (this.draggedTheme.themeId != theme.themeId) {
                console.log('different theme checking order');

                // they are different, check now if they are next to each othe
                if (this.checkOrder(theme)) {
                    theme.dropEffectNotAllowed = true;
                    return;
                }

                theme.dropEffect = true;
                theme.dropEffectNotAllowed = false;
                console.log('setovao na ', theme.dropEffect, theme.dropEffectNotAllowed);
            }
            else {
                console.log('same Theme - not enable for switching');
                theme.dropEffectNotAllowed = true;
            }
        } else console.log('themes are not define');
    }

    dragLeave(event, theme: ThemeModel) {
        console.log('dragLeave');
        theme.dropEffect = false;
        theme.dropEffectNotAllowed = false;
    }

}