import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from './services/user.service';
import { Router } from '@angular/router';
import { GroupModel } from './model/group.model';
import { GroupService } from './services/group.service';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
})
export class AppFooterComponent implements OnInit {

    private isAuthenticated: boolean = false;
    private subLoggedUser: Subscription = new Subscription();

    group: GroupModel;
    private subSelectedGroup: Subscription = new Subscription();

    private groupSelected = false;

    constructor(
        private groupService: GroupService,
        private userService: UserService,
        private router: Router) {

    }

    ngOnInit() {

        this.subLoggedUser = this.userService.userData$.subscribe(data => {
            console.log('footer onInit: ', data);
            this.isAuthenticated = data.isAuthenticated;
        });

        this.subSelectedGroup = this.groupService.selectedGroup$.subscribe(data => {
            this.group = data.group;
            if (this.group.groupId > 0)
                this.groupSelected = true
            else
                this.groupSelected = false;
        });

    }

    private clickHome() {
        console.log('clickHome  ');
        this.groupService.clearSelectGroup();
        this.router.navigate(['/group/all']);
    }

    private clickGroup() {
        console.log('clickGroup  ');
        this.router.navigate(['/group/' + this.group.groupId + '/themes']);
    }

    private clickTheme() {
        console.log('clickTheme  ');
        this.router.navigate(['/group/all']);
    }
}
