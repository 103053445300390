import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { StyleModel } from 'src/app/model/style.model';
import { GroupService } from 'src/app/services/group.service';
import { DisplayService } from 'src/app/services/display.service';
import { ThemesService } from 'src/app/services/theme.service';
import { UserService } from 'src/app/services/user.service';
import { JwtService } from 'src/app/services/jwt.service';
import { NotifyService } from 'src/app/notify/notify.service';
import { Subscription } from 'rxjs';

declare var ga: Function;

@Component({
  selector: 'public-group',
  templateUrl: './public-group.component.html',
  styleUrls: ['./public-group.component.css']
})
export class PublicGroupComponent implements OnInit {

  public pScrollHeight: StyleModel = new StyleModel();
  private subsAppHeight: Subscription = new Subscription();

  private tokenAquired = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private jwtService: JwtService,
    private displayService: DisplayService,
    private notifyService: NotifyService,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });

    this.pScrollHeight.width = '100%';
    // 45px -> breadcrumb
    this.pScrollHeight.height = (this.calculateUseAppHeght(200) - 20 - 45) + 'px';
  }

  /** calculate actual space that app hase - minus top bar, footer... */
  private calculateUseAppHeght(clientHeight: number): number {
    return (clientHeight - 20 - 45);
  }

  ngOnInit() {

    console.log('PublicGroupModule', this.router, this.route);
    console.log('PublicGroupModule - url: ', this.router.url);

    if (!this.jwtService.getAccessToken()) {
      // no JWT - get Public Access Token
      let url = this.router.url;
      if (url.indexOf('/public/') === 0) {
        console.log('PublicGroupModule - create JWT token');
        this.userService.authenticatePublicAccess().subscribe(response => {
          console.log(response);
          this.tokenAquired = true;
        },
          error => {
            this.notifyService.showError('Greska', 'Dogodila se greska');
            this.router.navigate(['/login']);
          });
      } else {
        console.log('public module - but not public url : ', url);
      }
    } else {
      console.log('jwt exist ');
      this.tokenAquired = true;
    }

    this.subsAppHeight = this.displayService.appHeigh$.subscribe(height => {
      this.pScrollHeight.height = this.calculateUseAppHeght(height) + 'px';
    });

  }


  ngOnDestroy() {
    this.subsAppHeight.unsubscribe();
  }

}
