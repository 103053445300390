import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Subscription } from 'rxjs';
import { CommentModel } from '../model/comment.model';
import { CommentService } from '../services/comment.service';

@Component({
    selector: 'comment-list',
    templateUrl: './comment-list.component.html'
})
export class CommentListComponent implements OnInit, OnDestroy {

    /** display comments with reply content -> true; Or just comments */
    @Input() withReplies: boolean = false;
    @Input() artifactType: number;
    @Input() comment: CommentModel;

    private artifactId: number;
    @Input('artifactId')
    set themeOnInput(artifactId: number) {
        // console.log('----------------------**********commnetListOnInput, artifactId', artifactId);
        this.artifactId = artifactId;
    }

    private subSelectedComments: Subscription = new Subscription();
    private subComments: Subscription = new Subscription();

    comments: Array<CommentModel> = [];

    constructor(private commentService: CommentService) {
    }

    ngOnInit() {
        // console.log('cmnt-list ngOnInit ', this.artifactType, this.artifactId);
        if (this.withReplies) {
            this.subComments = this.commentService.comments$.subscribe(data => {
                this.initComments(data);
            });
        }
        else {
            this.subComments = this.commentService.comments$.subscribe(data => {
                this.initCommentReplies(data);
            });
        }

    }

    ngOnDestroy() {
        this.subSelectedComments.unsubscribe();
        this.subComments.unsubscribe();
    }

    initComments(data: Array<CommentModel>) {
        this.comments = data.filter(cmnt => {
            return +cmnt.artifactType === +this.artifactType &&
                +cmnt.artifactId === +this.artifactId &&
                +cmnt.parentCommentId <= 0
        });
    }

    initCommentReplies(data: Array<CommentModel>) {
        this.comments = data.filter(cmnt => {
            return +cmnt.parentCommentId === +this.comment.commentId;
        });
    }
}