import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GroupCreateComponent } from 'src/app/group-create/group-create.component';
import { GroupAllComponent } from 'src/app/group-all/group-all.component';
import { GroupComponent } from 'src/app/group/group.component';
import { ThemeComponent } from 'src/app/theme/theme.component';
import { TaskComponent } from 'src/app/task/task.component';
import { GroupsComponent } from './groups.component';
import { CanDeactivateGuard } from 'src/app/can-deactivate.guard';
import { ThemesPreviewComponent } from 'src/app/themes-preview/themes-preview.component';
import { GroupsSingleViewComponent } from './groups-singleview.component';

const routes: Routes = [
  {
    path: 'group', component: GroupsSingleViewComponent,
    children: [
      {
        path: 'all',
        component: GroupAllComponent,
      },
      {
        path: 'create',
        component: GroupCreateComponent,
      }
    ]
  },
  {
    path: 'group', component: GroupsComponent,
    children: [
      { path: ':groupId', component: GroupComponent },
      {
        path: ':groupId/themes',
        component: ThemesPreviewComponent,
        canDeactivate: [CanDeactivateGuard],
        //data: { preload: true }  // Custom property we will use to track what route to be preloaded
      },
      {
        path: ':groupId/:themeId',
        component: ThemeComponent,
        canDeactivate: [CanDeactivateGuard],
        //data: { preload: true }  // Custom property we will use to track what route to be preloaded
      },
      {
        path: ':groupId/:themeId/:taskId',
        component: TaskComponent,
        canDeactivate: [CanDeactivateGuard],
      },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GroupsRoutingModule { }
