import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { WorkModel } from '../model/work.model';

/**
 *  
 */
@Injectable()
export class WorkService {

  private rootPath: string = `${environment.apiUrl}/work`;

  //All Works for one group
  private workSource = new BehaviorSubject<Array<WorkModel>>(new Array<WorkModel>());
  works$ = this.workSource.asObservable();

  constructor(private httpClient: HttpClient) { }

  // subscriptions on works
  getAllWorksForGroup(groupId: number): void {
    console.log('getAllWorksForGroup  ', groupId);
    let url: string = `${this.rootPath}/group/${groupId}`;
    this.httpClient.get<Array<WorkModel>>(url)
      .subscribe(
        data => {
          this.workSource.next(data);
        })
  }

  getWorks() {
    return this.workSource.getValue();
  }

  create(model: WorkModel): Observable<WorkModel> {
    let url: string = `${this.rootPath}`;
    return this.httpClient.post<WorkModel>(url, model)
      .pipe(
        tap(data => {
          this.addToLocalCache(data);
        })
      );
  }

  update(model: WorkModel): Observable<WorkModel> {
    let url: string = `${this.rootPath}/${model.workId}`;
    return this.httpClient.put<WorkModel>(url, model)
      .pipe(
        tap(data => {
          this.updateLocalCache(data);
        })
      );
  }

  delete(model: WorkModel): Observable<any> {
    let cid = model.workId;
    let url: string = `${this.rootPath}/${cid}`;
    return this.httpClient.delete(url)
      .pipe(
        tap(data => {
          this.removeFromLocalCache(model);
        })
      );
  }

  downloadFile(workId: number, uuidFileName: string): Observable<HttpResponse<Blob>> {
    let url = `${environment.apiUrl}/fileDownload/comment/${workId}/${uuidFileName}`;
    return this.httpClient.get(url, { responseType: 'blob', observe: 'response' });
  }

  /**
   * ------------------------------------------------------------------------------------
   * 
   * Cache data operation
   * when we do CRUD, immedeatly after receiving OK from API we localy persist new state 
   * until receved fresh (same) data from api - so user have seemles CRUD operation in case of slow network  
   * 
   * ------------------------------------------------------------------------------------
   */
  updateLocalCache(cmnt: WorkModel) {
    console.log('updateLocalCache', cmnt)
    //Find index of specific object using findIndex method.    
    let objIndex = this.workSource.getValue().findIndex((obj => obj.workId === cmnt.workId));
    //Update object 
    this.workSource.getValue()[objIndex] = cmnt;
    this.workSource.next(this.workSource.getValue());
  }
  removeFromLocalCache(cmnt: WorkModel) {
    console.log('removeFromLocalCache...', cmnt);
    //Find index of specific object using findIndex method.    
    let objIndex = this.workSource.getValue().findIndex((obj => obj.workId === cmnt.workId));
    console.log('removeFromLocalCache objIndex...', objIndex);
    //Update object  
    this.workSource.getValue().splice(objIndex, 1);
    this.workSource.next(this.workSource.getValue());
  }
  addToLocalCache(cmnt: WorkModel) {
    console.log('comment addToLocalCache', cmnt);
    //Update object 
    this.workSource.getValue().push(cmnt);
    this.workSource.next(this.workSource.getValue());
  }

}