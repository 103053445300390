import * as moment from 'moment';

export class GroupModel {

    groupId: number = -1;// default N/A
    name: string;
    description: string;
    publicStatus: boolean;
    publicUrl: string; // group hash
    likes: number;
    createdAt: moment.Moment;
    statusAt: moment.Moment;
    userId: number;
    userName: string;
    userInitials: string;
    userAvatarUrl: string;
    initial: string;
    admins: Array<number> = new Array();
}