import { Component, OnInit } from '@angular/core';
import { ThemeModel } from '../model/theme.model';
import { Subscription } from 'rxjs';
import { ThemesService } from '../services/theme.service';
import { Router } from '@angular/router';

@Component({
  selector: 'theme-grid-view',
  templateUrl: './theme-grid-view.component.html',
  styleUrls: ['./theme-grid-view.component.css']
})
export class ThemeGridViewComponent implements OnInit {

  private subThemes: Subscription = new Subscription();

  // list of themes 
  themes: Array<ThemeModel>;

  constructor(
    private router: Router,
    private themesService: ThemesService) { }

  ngOnInit() {
    this.subThemes = this.themesService.themes$.subscribe((themes: Array<ThemeModel>) => {
      this.themes = themes;
    });
  }

  selectTheme(theme: ThemeModel) { 
    // display group panel    
    this.router.navigate(['group/' + theme.groupId + '/' + theme.themeId]);
  }

}
