import { Component, OnInit } from '@angular/core';

import { GroupService } from "../services/group.service";

import { Router } from '@angular/router';
import { DisplayService } from '../services/display.service';
import { Subscription } from 'rxjs';
import { GroupModel } from '../model/group.model';

@Component({
    selector: 'group-all',
    templateUrl: './group-all.component.html'
})
export class GroupAllComponent implements OnInit {

    // all groups subscription
    groups: Array<GroupModel>;
    private subsGroups: Subscription = new Subscription();

    // group, selected
    group: GroupModel;
    private subsSelectedGroup: Subscription = new Subscription();

    constructor(
        private router: Router,
        private groupService: GroupService,
        private displayService: DisplayService,
    ) {
    }

    ngOnInit() {

        // we are showing all groups, none is selected
        this.groupService.clearSelectGroup();

        this.subsGroups = this.groupService.groups$.subscribe((data: Array<GroupModel>) => {
            this.groups = data;
        });

        this.subsSelectedGroup = this.groupService.selectedGroup$.subscribe(data => {
            this.group = data.group;
        });

        this.displayService.showGroupUI(false);
    }

    ngOnDestroy() {
        this.subsGroups.unsubscribe();
        this.subsSelectedGroup.unsubscribe();
    }

    /** Main method for selecting group and working with thems/tasks/commnets */
    selectGroup(group: GroupModel) {
        // hide old data before we start to load in background new data for new group
        this.displayService.showGroupUI(false);
        this.groupService.showGroup(group);
        // show user list for current group
        this.displayService.showGroupUI(true);
        // display group panel    
        this.router.navigate(['group/' + group.groupId]);
    }


    /** button action for crating new group */
    create() {
        // hide user list for current group
        this.displayService.showGroupUI(false);
        // navigate midle to crete view
        this.router.navigate(['group/create']);
    }

}