import { Component, ElementRef, AfterViewInit, Input, Output, EventEmitter, ContentChild, forwardRef } from '@angular/core';

import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { DomHandler } from 'primeng/api';
import { Header } from 'primeng/primeng';

declare var Quill: any;

export const EDITOR_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditorExt),
    multi: true
};

@Component({
    selector: 'p-editor-ext',
    styleUrls: ['./p-editor-ext.component.scss'],
    templateUrl: 'p-editor-ext.component.html',
    providers: [DomHandler, EDITOR_VALUE_ACCESSOR]
})
export class EditorExt implements AfterViewInit, ControlValueAccessor {

    @Output() onTextChange: EventEmitter<any> = new EventEmitter();

    @Output() onSelectionChange: EventEmitter<any> = new EventEmitter();

    @ContentChild(Header) toolbar;

    @Input() style: any;

    @Input() styleClass: string;

    @Input() placeholder: string;

    @Input() formats: string[];

    @Output() onInit: EventEmitter<any> = new EventEmitter();

    value: string;

    _readonly: boolean;

    onModelChange: Function = () => { };

    onModelTouched: Function = () => { };

    quill: any;

    constructor(public el: ElementRef, public domHandler: DomHandler) { }

    ngAfterViewInit() {
        let editorElement = this.domHandler.findSingle(this.el.nativeElement, 'div.ui-editor-content');
        let toolbarElement = this.domHandler.findSingle(this.el.nativeElement, 'div.ui-editor-toolbar');

        this.quill = new Quill(editorElement, {
            modules: {
                syntax: true,
                toolbar: toolbarElement,
                // https://github.com/quilljs/quill/issues/1379
                clipboard: {
                    matchVisual: false
                },
            },
            placeholder: this.placeholder,
            readOnly: this.readonly,
            formats: this.formats,
            theme: 'snow'  // or 'bubble'
        });

        if (this.value) {
            console.log(this.value);
            this.quill.pasteHTML(this.value);
            console.log(this.quill.root.innerHTML);
        }

        this.quill.on('text-change', (delta, oldContents, source) => {
            if (source === 'user') {
                let html = editorElement.children[0].innerHTML;
                let text = this.quill.getText().trim();
                if (text.length === 0) {
                    html = null;
                }

                this.onTextChange.emit({
                    htmlValue: html,
                    textValue: text,
                    delta: delta,
                    source: source
                });

                this.onModelChange(html);
                this.onModelTouched();
            }
        });

        this.quill.on('selection-change', (range, oldRange, source) => {
            this.onSelectionChange.emit({
                range: range,
                oldRange: oldRange,
                source: source
            });
        });

        this.onInit.emit({
            editor: this.quill
        });
    }

    writeValue(value: any): void {
        this.value = value;

        if (this.quill) {
            if (value)
                this.quill.pasteHTML(value);
            else
                this.quill.setText('');
        }
    }

    registerOnChange(fn: Function): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: Function): void {
        this.onModelTouched = fn;
    }

    getQuill() {
        return this.quill;
    }

    @Input() get readonly(): boolean {
        return this._readonly;
    }

    set readonly(val: boolean) {
        this._readonly = val;

        if (this.quill) {
            if (this._readonly)
                this.quill.disable();
            else
                this.quill.enable();
        }
    }
}
