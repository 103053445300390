import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MenuItem, ConfirmationService, FileUpload } from 'primeng/primeng';

import { TaskService } from '../services/task.service';
import { UserService } from '../services/user.service';

import { GroupModel } from '../model/group.model';
import { Subscription } from 'rxjs';
import { GroupService } from '../services/group.service';
import { TaskModel } from '../model/task.model';
import { environment } from '../../environments/environment';
import { JwtService } from '../services/jwt.service';
import { NotifyService } from '../notify/notify.service';
 
import { DisplayService } from '../services/display.service';
import { QuillService } from '../services/quill.service';
import { Router } from '@angular/router';

@Component({
    selector: 'task-view-edit',
    templateUrl: './task-view-edit.component.html',
    providers: [QuillService]
})
export class TaskViewEditComponent implements OnInit, OnDestroy {

    @ViewChild('fileInput') fileInput: FileUpload;

    /** navigate to Task page with clicakli title, or not if False */
    @Input('titleClicable') titleClicable: boolean = false;

    // url used when we attach file to comment - different from simple text comment!s
    private fileUploadURL: string;

    _task: TaskModel = new TaskModel();
    private quill: any;

    @Input('task')
    set task(task: TaskModel) {
        // console.log('----------------------**********taskOnInput', task);
        this._task = task;
        //20181210 must init form here also - when user come to URL form will be empty unles here initialized
        this.initForm();
    }
    get task(): TaskModel {
        return this._task;
    }

    // show/hide controll button base on component control - not user autorization
    @Input() showControl: boolean;
    // shor task navigation
    @Input() showTaskNavigation: boolean = false;

    private group: GroupModel;
    private subSelectedGroup: Subscription = new Subscription();

    private updateInProgress: boolean = false;

    selectedOptions: Array<MenuItem>;

    changeForm: FormGroup;

    editMode: boolean = false;

    spinnerValue: number = 0;

    constructor(
        private taskService: TaskService,
        private jwtService: JwtService,
        private displayService: DisplayService,
        private groupService: GroupService,
        private quillService: QuillService,
        private notifyService: NotifyService,
        private confirmationService: ConfirmationService, 
        private fb: FormBuilder,
        private router: Router,
        private userService: UserService) { }

    ngOnInit() {

        this.fileUploadURL = environment.apiUrl + '/fileUpload/task/edit';

        this.subSelectedGroup = this.groupService.selectedGroup$.subscribe(data => {
            this.group = data.group;
        });

        this.selectedOptions = [];
        if (this.isCreator() || this.isGroupAdmin()) {
            let deleteItem: MenuItem = {
                label: 'Obrisi',
                command: () => {
                    this.delete();
                }
            };
            this.selectedOptions.push(deleteItem);
        }
        if (this.isCreator()) {
            let editItem: MenuItem = {
                label: 'Izmeni',
                command: () => {
                    console.log('Izmeni...');
                    this.edit();
                }
            };
            this.selectedOptions.push(editItem);
        }
        // init form here, but also in task setter
        this.initForm();
    }

    ngOnDestroy() {
        this.subSelectedGroup.unsubscribe();
    }

    private openMenu(menu, event) {
        menu.show(event);
    }

    /**
      * Authorisation
      */
    isGroupAdmin(): boolean {
        try {
            return this.groupService.isGroupAdmin(this.userService.getDataStore().user.id);
        }
        catch (error) {
            console.log(error); return false;
        }
    }
    isCreator(): boolean {
        return this._task.userId === this.userService.getDataStore().user.id;
    }
    /** Is user authorise to see controls */
    showControlPerUser(): boolean {
        let result: boolean = false;
        result = this.isGroupAdmin() || this.isCreator();
        return result;
    }

    closeView() {

        // 20181114 - do not reset form! well lose all data if user come back to this view - all fields will be empty
        // this.changeForm.reset(); // 20181114 - removed!!

        // in case that user has close prevously this component - then we would have empty form - initialize it here again always
        this.initForm();
        this.updateInProgress = false;
        this.editMode = false;
    }

    private initForm() {
        this.changeForm = this.fb.group({
            taskId: [this._task.taskId],
            name: [this._task.name, Validators.required],
            description: [this._task.description]
        });
    }

    /** redirect on Task name selection */
    public goToTask() {
        this.router.navigate(['group/' + this.group.groupId + '/' + this._task.themeId + '/' + this._task.taskId]);
    }

    /**
     * QUILL
     */
    editorInit(event) {

        this.quill = event.editor;

        const toolbar = this.quill.getModule('toolbar');

        toolbar.addHandler('image', () => {
            this.quillService.selectLocalImage(
                this.quill, 'theme_' + this.group.groupId + '"_' + this.task.themeId);
        });
    }

    /**
       * fill ThemModel form input form. Set description form QILL
       */
    private processFormData(): void {
        let model: TaskModel = this.changeForm.value;
        //*** 20181010, change to get raw html from quil */
        //
        // set from quill directly (Angular cut somewhere img tags..)
        //
        this._task.name = model.name;
        this._task.userWorkUpload = model.userWorkUpload;
        this._task.description = this.quill.root.innerHTML;
    }

    /**
     * Delete / Edit section
     */
    private delete() {
        console.log('delete task: ', this._task);
        this.confirmationService.confirm({
            message: `Da li ste sigurni da zelite da obrisete zadatak? [` + this._task.name + ']',
            accept: () => {
                let themeId = this._task.themeId;
                this.taskService.delete(this._task, this.group.groupId)
                    .subscribe(
                        data => {
                            this.displayService.themeChanged(this._task.themeId);
                            // navigate to Theme page (we have delete Task - it no longer exist)
                            this.router.navigate(['group/' + this.group.groupId + '/' + themeId]);
                        },
                        err => {
                            this.notifyService.showError('Greska', 'prilikom brisanja zadatka');
                            this.notifyService.sendToExpLessServer('error deleting task: ' + this._task.taskId);
                            console.log(err);
                        },
                        () => {
                            this.closeView();
                        },
                    );
            },
            reject: () => {
                // do nothing
            }
        });
    }

    private edit(): void {
        this.editMode = true;
    }

    change() {

        this.updateInProgress = true;

        if (this.fileInput.files && this.fileInput.files.length > 0) {
            // user add file for upload, go with combine upload+commnet
            console.log('file upload section');
            this.fileInput.upload();
        }
        else {
            // no file - just edit task  

            // fill current Theme object with updated form data
            this.processFormData();

            this.taskService.update(this._task, this.group.groupId)
                .subscribe(
                    data => {
                        // display current changes
                        this._task = data;
                        // notify others that task is changed
                        this.displayService.taskChanged(this._task.taskId);
                    },
                    err => {
                        console.log(err);
                        this.notifyService.sendToExpLessServer('greska prilikom izmene zadatka');
                        this.notifyService.showError('Greska', 'prilikom izmene zadatka');
                    },
                    () => {
                        this.closeView();
                    });
        }

    }

    deleteFile(uuidFileName: string, originalFileName: string) {
        console.log('delete file ', uuidFileName, originalFileName);
        this.confirmationService.confirm({
            message: `Da li ste sigurni da zelite da obrisete file: "${originalFileName}" ?`,
            accept: () => {
                this.updateInProgress = true;
                this.taskService.deleteFile(this._task, uuidFileName)
                    .subscribe(
                        response => {
                            console.log('file deleted, refreshing task', response);
                            this.task = response;
                            response.editMode = true;
                            this.updateInProgress = false;
                            this.displayService.taskChanged(this._task.taskId);
                        },
                        error => {
                            console.log('can not delete file from task');
                            this.closeView();
                            this.notifyService.sendToExpLessServer('greska prilikom brisanja zadatka');
                            this.notifyService.showError('Greska', 'prilikom brisanja zadatka');
                        });
            },
            reject: () => {
                this.updateInProgress = false;
                // do nothing
            }
        });
    }

    downloadFilePath(uuidFileName: string) {
        return `${environment.apiUrl}/fileDownload/task/${this._task.taskId}/${uuidFileName}`;
    }

    /**
        * 
        * FileUpload, action before send -> 
        *  -  add  JWT token! and 
        *  -  fill comment text part: name and desctirpion
    */
    onBeforeSend(event) {
        console.log(event);

        // fill current Theme object with updated form data
        this.processFormData();

        event.xhr.setRequestHeader("Authorization", `Bearer ${this.jwtService.getAccessToken()}`);
        event.formData.append('dataJson', JSON.stringify(this._task));
    }

    onUpload(event) {
        console.log('onUpload task', event);

        let taskUpdated = JSON.parse(event.xhr.response);
        // display current changes
        this._task = taskUpdated;

        // update change in Themes repository
        this.taskService.updateLocalCache(taskUpdated);

        // notify others that task is changed
        this.displayService.taskChanged(this._task.taskId);

        this.closeView();
    }

}