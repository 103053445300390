import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DisplayService } from './display.service';
import { GroupService } from './group.service';
import { ThemesService } from './theme.service'; 
import { TaskService } from './task.service';
import { NextViewService } from './nextview.service';


@Injectable()
export class PlutaRouterService {

  public PATH_PUBLIC = 'public';
  public PATH_GROUP = 'group';

  private isPublic: boolean = false;

  private isGroup: boolean = false;
  private isTheme: boolean = false;
  private isTask: boolean = false;


  constructor(
    private router: Router,
    private groupService: GroupService,
    private themeService: ThemesService,
    private taskService: TaskService,
    private nextViewService: NextViewService,
    private displayService: DisplayService,
  ) {

    this.router.events.subscribe(event => {
      // console.log('r.s. : ', event);
      if (event instanceof NavigationEnd) {
        // console.log('r.service: ', event.urlAfterRedirects);
        // console.log(this.router.parseUrl(event.urlAfterRedirects));
        console.log(this.router.parseUrl(event.urlAfterRedirects).root.children['primary'].segments);
        let trt = this.router.parseUrl(event.urlAfterRedirects).root.children['primary'].segments;

        /** is URL public or private */
        if (trt && trt.length >= 1) {
          if (trt[0].path === this.PATH_PUBLIC) {
            this.isPublic = true;
          }
          else {
            this.isPublic = false;
          }

          if (trt && trt.length >= 2 &&
            (trt[0].path === this.PATH_PUBLIC || trt[0].path === this.PATH_GROUP)) {
            // console.log('length: ', trt.length, trt[1]);
            if (trt.length === 2) {
              if (trt[1].path === 'all') {
                this.clearAll();
                displayService.breadCrumb(1);
              }
              else if (trt[1].path === 'create') {
                this.clearAll();
                displayService.breadCrumb(1);
              }
              else {
                console.log(' - set group with name');
                this.isGroup = true;
                displayService.breadCrumb(2);
              }
            }
            if (trt.length === 3) {
              this.isTheme = true;
              displayService.breadCrumb(3);
            } if (trt.length === 4) {
              this.isTask = true;
              displayService.breadCrumb(4);
            }
          }

        }
      }
    });

  }

  private clearAll() {
    this.isGroup = false;
    this.isTheme = false;
    this.isTask = false;
  }

  public isUrlPublic(): boolean {
    return this.isPublic;
  }

  public navigatePreviouseTask() {

    let task = this.nextViewService.getPreviouseOnSelectedTask();

    // set as selected, so subscription will know
    this.taskService.setSelectedTask(task);
    if (task) {
      // task exist
      this.router.navigate(['group', this.groupService.getSelectedGroup().groupId, task.themeId, task.taskId]);

    } else {

      // this is first task, go to previuose Theme
      let theme = this.nextViewService.getPreviouseOnSelectedTheme();

      this.themeService.setSelectedTheme(theme);

      if (theme) {
        // theme exist
        this.themeService.setSelectedTheme(theme);
        this.router.navigate(['group', this.groupService.getSelectedGroup().groupId, theme.themeId]);
      } else {
        // nextTheme dose not exist - go to begining of current group
        this.router.navigate(['group', this.groupService.getSelectedGroup().groupId]);
      }
    }

  }

  public navigateNextTask() {

    let task = this.nextViewService.getNextOnSelectedTask();

    // set as selected, so subscription will know
    this.taskService.setSelectedTask(task);
    if (task) {
      // task exist
      this.router.navigate(['group', this.groupService.getSelectedGroup().groupId, task.themeId, task.taskId]);

    } else {
      // this is last task, go to next Theme
      let theme = this.nextViewService.getNextOnSelectedTheme();

      this.themeService.setSelectedTheme(theme);

      console.log('task is null, go to next heme ', theme);
      if (theme) {
        // theme exist
        this.router.navigate(['group', this.groupService.getSelectedGroup().groupId, theme.themeId]);
      } else {
        // nextTheme dose not exist - go to begining of current group
        this.router.navigate(['group', this.groupService.getSelectedGroup().groupId]);
      }
    }
  }


}
