import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { TaskService } from '../services/task.service';
import { Subscription } from 'rxjs';
import { TaskModel } from '../model/task.model';
import { CommentService } from '../services/comment.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ThemesService } from '../services/theme.service';
import { GroupService } from '../services/group.service';
import { UserService } from '../services/user.service';
import { MenuItem } from 'primeng/api';
import { TaskViewEditComponent } from '../task-view-edit/task-view-edit.component';
import { DisplayService } from '../services/display.service';


declare var ga: Function;

@Component({
    selector: 'task',
    templateUrl: './task.component.html'
})
export class TaskComponent implements OnInit, OnDestroy {

    @ViewChild(TaskViewEditComponent) taskViewEditChild;

    // task
    private task: TaskModel = new TaskModel();
    private taskSubscription: Subscription = new Subscription();

    // private showComments: boolean = true;

    selectedOptions: Array<MenuItem>;

    editMode: boolean = false;


    constructor(
        private groupService: GroupService,
        private taskService: TaskService,
        private themesService: ThemesService,
        private router: Router,
        private route: ActivatedRoute,
        private displayService: DisplayService,
        private userService: UserService) {

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                ga('set', 'page', event.urlAfterRedirects);
                ga('send', 'pageview');
            }
        });

        this.route.params.subscribe(params => {
            console.log('**********  ', params, this.task, this.taskService.getSelectedTask());
            let themeId = params.themeId;
            let groupId = params.groupId;
            let groupHash = params.groupHash;
            let taskId = params.taskId;
            if (isNaN(groupId)) {

                // groupId is not specified, check for groupHash
                if (groupHash || isNaN(themeId) || isNaN(taskId)) {
                    /** we have groupHash and themeId*/
                    console.log('hash and themeId exist  ', groupHash, themeId);
                    //if theme is not selected - user come to this page from directly clicking Link
                    if (this.themesService.getSelectedTheme().themeId < 0) {

                        // 20181002 always close child element
                        if (this.taskViewEditChild)
                            this.taskViewEditChild.editMode = false;
                        //
                        // -----  init Group and Themes from received URL -----
                        //
                        console.log('init group and thems from api', groupHash, themeId);
                        this.groupService.setGroupByHashAndThemeTaskFromApi(groupHash, themeId, taskId);
                        this.displayService.showGroupUI(true);

                    }

                }
                else {
                    // no hash - goto home
                    this.router.navigate(['group/all']);
                }
            } else {

                console.log('route params subscribe TASK ', groupId, themeId, taskId, this.taskService.getSelectedTask().taskId);

                // 20181002 always close child element
                if (this.taskViewEditChild)
                    this.taskViewEditChild.editMode = false;

                // task is not selected - user come to this page from directly clicking Link  
                //
                // -----  init Group and Themes from received URL -----
                //
                console.log('init task/theme/group');
                this.groupService.setGroupByIdAndThemeTaskFromApi(groupId, themeId, taskId);
                // show view
                this.displayService.showGroupUI(true);
            }
        });


    }

    ngOnInit() {

        console.log('ngOnInit() TASK');

        this.taskSubscription = this.taskService.selectedTask$.subscribe((selected: TaskModel) => {

            this.task = selected;
            // this.showComments = false;
            // 20181002 always close child element
            if (this.taskViewEditChild)
                this.taskViewEditChild.editMode = false;

        });
    }

    ngAfterViewInit() {
        // console.log('ngAfterViewInit', this.taskViewEditChild);
    }

    ngOnDestroy() {
        this.taskSubscription.unsubscribe();
    }

    // toggleComments(event: any) {
    //     // this.showComments = !this.showComments;
    // }

    /**
   * Authorization
   */
    isGroupAdmin(): boolean {
        return this.groupService.isGroupAdmin(this.userService.getDataStore().user.id);
    }
    isCreator(): boolean {
        return this.task.userId === this.userService.getDataStore().user.id;
    }
    /** Is user authorise to see controls */
    showControlPerUser(): boolean {
        let result: boolean = false;
        //|| this.isCreator(this.userService.getDataStoreState().user.id)
        result = this.isGroupAdmin();
        return result;
    }

    /**
     * comon as taskView
     */
    // must be done this way because otherwise PrimeNG throws ObjectUnsubscribedError 
    openMenu(task: TaskModel, menu: any, event: any) {
        this.selectedOptions = [];
        let deleteItem: MenuItem = {
            label: 'Obrisi',
            command: () => {
                console.log('delete from parent', this.taskViewEditChild);
                this.taskViewEditChild.delete(task);
            }
        };
        this.selectedOptions.push(deleteItem);
        let editItem: MenuItem = {
            label: 'Izmeni',
            command: () => {
                this.taskViewEditChild.edit(task);
            }
        };
        this.selectedOptions.push(editItem);

        // PrimeNg's event for showing menu
        menu.show(event);
    }

}