import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MenuItem, ConfirmationService } from 'primeng/primeng';

import { CommentService } from '../services/comment.service';
import { UserService } from '../services/user.service';

import { CommentModel } from '../model/comment.model';
import { GroupService } from '../services/group.service';
import { environment } from '../../environments/environment';
import { NotifyService } from '../notify/notify.service';


@Component({
    selector: 'comment-view-edit',
    styleUrls: ['./comment-view-edit.component.scss'],
    templateUrl: './comment-view-edit.component.html'
})
export class CommentViewEditComponent implements OnInit {

    @Input() comment: CommentModel; 

    @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('cmntarea') cmntarea: ElementRef;

    selectedOptions: Array<MenuItem>;

    changeForm: FormGroup;

    showAddComment: boolean = false;
    editMode: boolean = false;
    editInProgress: boolean = false;

    constructor(
        private commentService: CommentService,
        private groupService: GroupService,
        private confirmationService: ConfirmationService,
        private notifyService: NotifyService,
        private fb: FormBuilder,
        private userService: UserService,
        public renderer: Renderer2) { }

    ngOnInit() {

        this.selectedOptions = [];
        if (this.isCreator() || this.isGroupAdmin()) {
            let deleteItem: MenuItem = {
                label: 'Obrisi',
                command: () => {
                    this.delete();
                }
            };
            this.selectedOptions.push(deleteItem);
        }
        if (this.isCreator()) {
            let editItem: MenuItem = {
                label: 'Izmeni',
                command: () => {
                    this.editMode = true;
                }
            };
            this.selectedOptions.push(editItem);
        }

        this.initForm();
    }

    private openMenu(menu, event) {
        console.log('open menu', event);
        console.log('comment  ', this.comment);
        // must stop propagation or DOM will be all rendered and we'll have large timeouts!
        // event.stopPropagation();
        // PrimeNg's event for showing menu
        menu.show(event);
    }
    /** 
     * Autogrow by hegtht. Also check if comment is emty - set form to pristine 
     * Case: user enter text - then remove all, we do not wont inpput to be highlighted
     */
    autogrow() {

        if (!this.changeForm.controls['comment'].value) {
            console.log(this.changeForm.controls['comment'].value, 'for is empty - set to pristine - reset it')
            this.changeForm.reset();
        }

        let scrollHeigh = this.cmntarea.nativeElement.scrollHeight;
        // console.log(scrollHeigh);
        if (scrollHeigh < 20) return;
        this.renderer.setStyle(this.cmntarea.nativeElement, 'overflow', 'hidden');
        this.renderer.setStyle(this.cmntarea.nativeElement, 'width', '100%');
        this.renderer.setStyle(this.cmntarea.nativeElement, 'resize', 'none');
        this.renderer.setStyle(this.cmntarea.nativeElement, 'height', '0px');
        this.renderer.setStyle(this.cmntarea.nativeElement, 'height', scrollHeigh + 'px');
    }


    /**
     * Autorisation
     */
    isGroupAdmin(): boolean {
        return this.groupService.isGroupAdmin(this.userService.getDataStore().user.id);
    }
    isCreator(): boolean {
        return this.comment.userId === this.userService.getDataStore().user.id;
    }
    showControls(): boolean {
        let result: boolean = false;
        result = this.isGroupAdmin() || this.isCreator();
        return result;
    }

    /**
     * UI control
     */

    close() {
        this.editMode = false;
        this.changeForm.controls['comment'].patchValue(this.comment.comment);
    }

    /**
     * EDIT / DELETE
     */

    change() {
        this.fillFormValue();
        this.editInProgress = true;
        this.commentService.update(this.comment).subscribe(
            data => {
                this.onChange.emit(true);
                this.editMode = false;
            },
            error => {
                this.notifyService.showError('Greska', 'Prilikom izmene komentara.');
                this.notifyService.sendToExpLessServer('Greska prilikom izmene komentara.');
            },
            () => { this.editInProgress = false; });
    }

    private delete() {
        this.confirmationService.confirm({
            message: `Da li ste sigurni da zelite da obrisete komentar?`,
            accept: () => {
                this.commentService.delete(this.comment).subscribe(
                    data => {
                        console.log('uspesno obrisan');
                        this.onChange.emit(true);
                    },
                    error => {
                        this.notifyService.showError('Greska', 'Prilikom brisanja komentara.');
                        this.notifyService.sendToExpLessServer('Greska, prilikom brisanja komentara.');
                    });
            },
            reject: () => {
                // do nothing
            }
        });
    }

    /**
     * Form actions
     */
    private initForm() {
        this.changeForm = this.fb.group({
            commentId: [this.comment.commentId],
            comment: [this.comment.comment, Validators.required]
        });
    }

    private fillFormValue(): void {
        let model: CommentModel = this.changeForm.value;
        this.comment.comment = model.comment;
    }

    /**
     *  Download file for comment
     */

    downloadFilePath(uuidFileName: string) {
        return `${environment.apiUrl}/fileDownload/comment/${this.comment.commentId}/${uuidFileName}`;
    }

}